export const Pages = [
    {
        title: 'Home',
        cName: 'nav-links'
    },
    {
        title: 'About',
        cName: 'nav-links'
    },
    {
        title: 'Contact Info',
        cName: 'nav-links'
    }
]
